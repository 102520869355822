import IconButton from '@components/button/IconButton';
import { DashboardSection } from '@components/dashboard';
import { SURVEY } from '@constants/terminology';
import { BasicAlert, SimpleTooltip, WorkInProgress } from '@g17eco/molecules';
import { IN_PROGRESS_JOB_STATUSES, INVALID_JOB_STATUSES, JobStatus } from '@g17eco/types/background-jobs';
import { Button } from 'reactstrap';

interface Props {
  pptxReportJobStatus: JobStatus | undefined;
  scoreJobStatus: JobStatus | undefined;
  onExcelDownload: () => void;
  onPPTXDownload: () => void;
  regenerateScores: () => void;
}
export const AssessmentInsightsOverview = (props: Props) => {
  const { pptxReportJobStatus, scoreJobStatus, onExcelDownload, onPPTXDownload, regenerateScores } = props;

  if (!scoreJobStatus) {
    return null;
  }

  if (INVALID_JOB_STATUSES.includes(scoreJobStatus)) {
    return (
      <BasicAlert type='danger'>There was an error calculating the assessment scores. Please try again.</BasicAlert>
    );
  }

  const isGeneratingScore = IN_PROGRESS_JOB_STATUSES.includes(scoreJobStatus);

  if (isGeneratingScore) {
    return (
      <WorkInProgress
        title={`Your materiality ${SURVEY.SINGULAR} is currently generating and you will be notified once it is completed`}
      />
    );
  }

  if (scoreJobStatus === JobStatus.Completed) {
    return (
      <DashboardSection>
        <h5 className='m-0 mb-2 fw-bold'>Financial Materiality {SURVEY.SINGULAR}</h5>
        <div>
          This {SURVEY.SINGULAR} serves as a foundation for <strong>Rawarbi Energy’s</strong> sustainability journey,
          guiding the development of a robust sustainability strategy and facilitating transparent reporting on ESG
          performance.
        </div>
        <div className='w-100 d-flex gap-2 justify-content-end mt-4'>
          <SimpleTooltip text='Coming soon'>
            <Button color='transparent'>
              <i className='fal fa-circle-nodes mr-2' /> Export to Company Tracker
            </Button>
          </SimpleTooltip>
          <Button color='secondary' onClick={onExcelDownload}>
            <i className='fal fa-file-excel mr-2' />
            All results
          </Button>
          {pptxReportJobStatus && IN_PROGRESS_JOB_STATUSES.includes(pptxReportJobStatus) ? (
            <Button color='primary' disabled={true}>
              <i className='fal fa-circle mr-2' />
              Generating report
            </Button>
          ) : (
            <Button color='primary' onClick={onPPTXDownload} disabled={pptxReportJobStatus !== JobStatus.Completed}>
              <i className='fal fa-file-powerpoint mr-2' />
              Download report
            </Button>
          )}

          <SimpleTooltip
            text={
              isGeneratingScore ? 'Scores are being calculated. Cannot regenerate until complete' : 'Regenerate scores'
            }
          >
            <IconButton
              data-testid={'btn-regenerate'}
              onClick={regenerateScores}
              outline={false}
              color='transparent'
              className='text-ThemeAccentDark'
              icon='fal fa-repeat'
              disabled={isGeneratingScore}
            />
          </SimpleTooltip>
        </div>
      </DashboardSection>
    );
  }
};
